import { KPICard } from '../../kpi-card/KPICard';
import { useIntl } from 'react-intl';
import { ChartLineIcon } from '@trawa-energy/ui-kit';
import { ConsumptionChart } from '../../charts/ConsumptionChart';
import { formatEnergy } from '../../../utils/formatting/formatPowerConsumption';
import { Temporal } from 'temporal-polyfill';
import { KPICardFooter } from './KPICardFooter';
import { KPICardHeader } from './KPICardHeader';
import { useMemo } from 'react';

import { useGetConsumption, useGetTotalConsumptionInfo } from '../../../hooks/requests';

export const ConsumptionCard = () => {
    const intl = useIntl();
    const name = 'kpi-consumption';

    const today = useMemo(() => Temporal.Now.plainDateISO(intl.timeZone!), [intl.timeZone]);

    const {
        yearMonths,
        consumptions,
        isLoading: isLoadingGetConsumption,
    } = useGetConsumption({
        today,
        interval: 'month',
    });

    // const { data: supplyProducts } = useGetSupplyByProductType({
    //     dateRange: dateRangeLastNMonths(6, today)
    // });

    const {
        totalConsumptions,
        averageConsumption,
        firstIngestionDate,
        isLoading: isLoadingConsumptions,
    } = useGetTotalConsumptionInfo();

    const isLoading = isLoadingGetConsumption || isLoadingConsumptions;

    const isTotalConsumptionDataReady = !!(totalConsumptions && averageConsumption && firstIngestionDate);
    const title = intl.formatMessage({ id: 'kpi.consumption.title' });

    const subtitle = isTotalConsumptionDataReady
        ? intl.formatMessage(
              { id: 'kpi.consumption.subtitle' },
              {
                  totalConsumptions: formatEnergy(totalConsumptions, intl.locale),
                  averageConsumption: formatEnergy(averageConsumption, intl.locale),
                  firstIngestionDate: intl.formatDate(firstIngestionDate.toString(), {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                  }),
              },
          )
        : intl.formatMessage({ id: 'kpi.consumption.noDataAvailableSubtitle' });

    return (
        <KPICard.Root name={name} isLoading={isLoading}>
            <KPICard.Header testId={name}>
                <KPICardHeader title={title} subtitle={subtitle} Icon={ChartLineIcon} />
            </KPICard.Header>

            <KPICard.Content name={name} showEmpty={!consumptions || !isTotalConsumptionDataReady}>
                <ConsumptionChart
                    height={'230px'}
                    isLoading={isLoading}
                    isTotalConsumptionDataReady={isTotalConsumptionDataReady}
                    consumptions={consumptions}
                    yearMonths={yearMonths}
                    today={today}
                />
            </KPICard.Content>

            <KPICard.Footer name={name}>
                <KPICardFooter text={intl.formatMessage({ id: 'kpi.consumption.linkText' })} url="/consumption" />
            </KPICard.Footer>
        </KPICard.Root>
    );
};
