import { trpc } from '../../utils/trpc.js';
import { getPlainDateTimeFromString } from '../../utils/getPlainDateTimeFromString';
import { Temporal } from 'temporal-polyfill';
import { useState } from 'react';
import { useIntl } from 'react-intl';

// get the number of months between two dates + the fraction of the last month
const getNumOfMonths = (startDate: Temporal.PlainDate, endDate: Temporal.PlainDate) => {
    return endDate.since(startDate).total({ unit: 'months', relativeTo: startDate });
};

export const useGetTotalConsumptionInfo = () => {
    const intl = useIntl();
    const [today] = useState(Temporal.Now.plainDateISO(intl.timeZone!));
    const { data: totalConsumptions, isLoading } = trpc.dashboard.getTotalConsumption.useQuery({});

    const { totalKWh, firstIngestionDate: startDate } = totalConsumptions ?? {};
    if (!totalKWh || !startDate) {
        return {
            isLoading,
        };
    }
    const totalKWhValue = Number(totalKWh);
    const firstIngestionDate = getPlainDateTimeFromString(startDate).toPlainDate();

    return {
        totalConsumptions: totalKWhValue,
        averageConsumption: totalKWhValue / getNumOfMonths(firstIngestionDate, today),
        firstIngestionDate,
        isLoading,
    };
};
