import { useContractPartnerId } from '../../userSettings';
import { trpc } from '../../utils/trpc';
import { Temporal } from 'temporal-polyfill';
import { getPlainDateTimeFromString } from '../../utils/getPlainDateTimeFromString';
import { Interval } from '../useFilterValues.tsx';

export const dateRangeLastNMonths = (n: number, today: Temporal.PlainDate) => {
    const startDate = today.subtract({ months: n - 1 });
    return {
        from: Temporal.PlainDate.from({ year: startDate.year, month: startDate.month, day: 1 }).toString(),
        exclusiveTo: today.add({ days: 1 }).toString(),
    };
};

type ConsumptionHookProps = {
    interval?: Interval;
    today: Temporal.PlainDate;
};

export const useGetConsumption = ({ today, interval = 'month' }: ConsumptionHookProps) => {
    const contractPartnerId = useContractPartnerId();

    const { data: volumeEntries, isLoading } = trpc.dashboard.getVolumeEntries.useQuery({
        dateRange: dateRangeLastNMonths(6, today),
        contractPartnerId,
        aggregationInterval: interval === '15min' ? undefined : interval,
    });

    if (!volumeEntries) {
        return { yearMonths: [], consumptions: [] };
    }

    const volumeEntriesGroupedByMonth = Object.groupBy(volumeEntries, volumeEntry =>
        getPlainDateTimeFromString(volumeEntry.timestampStart).toPlainYearMonth().toString(),
    );

    const yearMonths = Object.keys(volumeEntriesGroupedByMonth);
    const consumptions = yearMonths.map(yearMonth =>
        volumeEntriesGroupedByMonth[yearMonth]!.reduce((acc, currentValue) => acc + currentValue.kwh, 0),
    );

    return {
        yearMonths: yearMonths.map(yearMonth => Temporal.PlainYearMonth.from(yearMonth)),
        consumptions,
        isLoading,
    };
};
