import { inferRouterOutputs } from '@trpc/server';
import { AppRouter } from '@trawa-energy/portal-api/appRouter';

export type VolumeEntry = inferRouterOutputs<AppRouter>['dashboard']['getVolumeEntries'][0];

export enum KpiKeys {
    Consumption = 'consumption',
    Emission = 'emission',
    Solar = 'solar',
}

export enum UnitKeys {
    Kwh = 'kWh',
    MWh = 'MWh',
    GWh = 'GWh',
    Tones = 't',
}

export type KpiType = `${KpiKeys}`;
export type ValueAndUnitType = {
    value: number;
    unit: string;
};
